import {apiUrls} from "../../assets/api/apiURL";

export const cartDataFetch = async (setCart ,setTempCart) => {
    const options = {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache'
    }

    return fetch(`${apiUrls.mainURL}${apiUrls.cart.cartData}`,options)
        .then((res) => res.json())
        .then((json) => {
            setCart(json.cart)
            if(setTempCart) {
                setTempCart(json.cart)
            }

            return json
        })
}

export const addUpdateProductToCart = async (params) => {
    const formData = new FormData();
    formData.append("id_product", params.id);
    formData.append("quantity", params.quantity.toString());
    if(params.phantomId) {
        formData.append("id_phantom", params.phantomId);
    }

    const options = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        body: formData
    }

    return await fetch(`${apiUrls.mainURL}${apiUrls.cart.add}`,options)
        .then((res) => res.json())
        .then((json) => {
            return json
        })
}

export const updateProductInCart = async (params) => {
    const formData = new FormData();
    formData.append("id_product", params.id);
    formData.append("quantity", params.quantity.toString());

    const options = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        body: formData
    }

    return await fetch(`${apiUrls.mainURL}${apiUrls.cart.update}`,options)
        .then((res) => res.json())
        .then((json) => {
            return json
        })
}

export const deleteProductFromCart = async (item) => {
    const formData = new FormData();
    formData.append("id_product", item.id_product);

    const options = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        body: formData
    }

    return fetch(`${apiUrls.mainURL}${apiUrls.cart.delete}`,options)
        .then((res) => res.json())
        .then((json) => {
            return json
        })
}