import React from 'react';
import ReactDOM from 'react-dom/client';
import Builder from "./Containers/Builder/Builder";
import ThemeContextProvider from "./Components/Context/context";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeContextProvider>
        <Builder />
    </ThemeContextProvider>
);

