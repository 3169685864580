import React, {useState, createContext, useEffect, useCallback} from 'react';
import {cartDataFetch} from "../CartFunctions/cartFunctions";
import {apiUrls} from "../../assets/api/apiURL";
import {eraseCookie} from "../Helpers/cookies";

export const ThemeContext = createContext({
    menuData: null,
    cartData : [],
    auth: null,
    user : null,
    modalResetPasswordOpened: false,
    textPages : null,
    currency : null,
    contact : null
});

const ThemeContextProvider = props => {

    const [menuData, setMenuData] = useState(null)
    const [cartData, setCartData] = useState(null)
    const [auth, setAuth] = useState(null)
    const [user, setUser] = useState(null)
    const [modalResetPasswordOpened, setModalResetPasswordOpened] = useState(false)
    const [textPages, setTextPages] = useState(null)
    const [currency, setCurrency] = useState(null)
    const [contact , setContact] = useState(null)

    const updateMenuData = (item) => {
        setMenuData(item)
    }

    const updateCartData = async (setTemp) => {
       return cartDataFetch(setCartData, setTemp)
    }

    const updateAuthAndUser = (item) => {
        setAuth(item.token)
        setUser(item.user.email)
    }

    const updateModalResetPasswordOpened = (item) => {
        setModalResetPasswordOpened(item)
    }

    const updateTextPages = (data) => {
        setTextPages(data)
    }

    const updateContact = (data) => {
        setContact(data)
    }

    const refresh = useCallback(() => {

        function timeDifference(date1,date2) {
            date1 = new Date(date1)
            date2 = new Date(date2)

            var difference = date1.getTime() - date2.getTime();

            var daysDifference = Math.floor(difference/1000/60/60/24);
            difference -= daysDifference*1000*60*60*24

            var hoursDifference = Math.floor(difference/1000/60/60);
            difference -= hoursDifference*1000*60*60

            return Math.floor(difference / 1000 / 60)
        }

        if (auth === null || timeDifference(Date.now(),parseInt(localStorage.getItem("time"))) > 13) {
            fetch(`${apiUrls.mainURL}${apiUrls.user.token}`, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                cache: 'no-cache',
            })
                .then((res) => res.json())
                .then((json) => {
                    if(json.status !== 401) {
                        setAuth(json.token)
                        setUser(json.user.email)
                        localStorage.setItem("time",Date.now().toString())
                        localStorage.setItem("auth", "true")
                    } else {
                        setAuth(false)
                        setUser(false)
                        localStorage.removeItem("time")
                        localStorage.setItem("auth", "false")
                    }
                })
        }
    },[auth])

    const logOut = () => {
        if(auth) {
            const formData = new FormData();

            formData.append("token", auth.toString());

            fetch(`${apiUrls.mainURL}${apiUrls.user.logout}`, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                cache: 'no-cache',
                body: formData
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.success === true) {
                        cartDataFetch(setCartData)
                        setAuth(false)
                        eraseCookie("checkoutForm")
                        localStorage.setItem("auth", "false")
                        updateCurrency(localStorage.getItem("currency"))
                    }
                })
        }
    }

    const updateCurrency = (currency, reload) => {

        const formData = new FormData();

        formData.append("currency", currency);

        fetch(`${apiUrls.mainURL}${apiUrls.changeCurrency}`,{
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            cache: 'no-cache',
            body : formData
        })
            .then((res) => res.json())
            .then((json) => {
                localStorage.setItem("currency", currency)
                if(reload) {
                    reload()
                }
            });
    }

    useEffect(() => {
        setInterval(() => {
            refresh()
        },780000)
    },[refresh])

    useEffect(() => {
        refresh()
    },[refresh])

    useEffect(() => {
        if(cartData !== null) {
            setCurrency(cartData.currency)
        }
    },[cartData])

    useEffect(() => {
        const onStorageUpdate = (e) => {
            const { key , newValue , oldValue } = e;

            if (key === "auth") {
                if (newValue !== oldValue) {
                    fetch(`${apiUrls.mainURL}${apiUrls.user.token}`, {
                        method: 'POST',
                        mode: 'cors',
                        credentials: 'include',
                        cache: 'no-cache',
                    })
                        .then((res) => res.json())
                        .then((json) => {
                            if(json.status !== 401) {
                                setAuth(json.token)
                                setUser(json.user.email)
                                localStorage.setItem("time",Date.now().toString())
                                localStorage.setItem("auth", "true")
                            } else {
                                setAuth(false)
                                setUser(false)
                                localStorage.removeItem("time")
                                localStorage.setItem("auth", "false")
                            }
                            cartDataFetch(setCartData)
                        })
                }
            }
        };

        window.addEventListener("storage", onStorageUpdate);
        return () => {
            window.removeEventListener("storage", onStorageUpdate);
        };
    }, []);

    return(
        <ThemeContext.Provider value={{
            menuData : menuData,
            cartData : cartData,
            auth : auth,
            user : user,
            modalResetPasswordOpened: modalResetPasswordOpened,
            textPages : textPages,
            currency : currency,
            contact : contact,
            updateMenuData,
            updateCartData,
            updateAuthAndUser,
            refresh,
            logOut,
            updateModalResetPasswordOpened,
            updateTextPages,
            updateContact,
            updateCurrency
        }}>
            {props.children}
        </ThemeContext.Provider>
    )
}

export default ThemeContextProvider;